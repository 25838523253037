<template>
    <div class="previewPage">
        <a-modal v-model="showSubmit" :title="previewForm.options.thanks.headline || 'Thank you for submitting'" :footer="null" centered>
            {{previewForm.options.thanks.text || 'Please note that this is a test submission.'}}
        </a-modal>
        <a-row type="flex" justify="space-between" v-if="theApp" class="topbar top-bar--fixed" style="box-shadow: 0px 8px 28px rgba(100, 100, 100, 0.1); background: white;">

            <a-col :span="8" class="dF">
                <router-link to="/select" v-if="$bhapp === 'admin'" class="dF">
                    <img src="@/assets/logo.svg" style="max-height:40px;"/>
                </router-link>
                <a :href="`https://admin.bildhive.${tld}/apps`" class="dF aC" v-else>
                    <img src="@/assets/logo.svg" :alt="theApp.name" style="width:130px; max-height: 50px;"/>
                    <div class="f1 fw-300">
                        <span class="text-lg text-black ml-4 block lXT">{{instance.name}}</span>
                    </div>
                </a>
            </a-col>

            <a-col style="display:flex; align-items:center;">
                <div style="display:flex;justify-content:flex-end">
                    <a-button v-if="formType !== 'Edit Form' && formType !== 'Add New Form'" @click="back" class="mr-3" style="background-color: transparent;">BACK</a-button>
                    <a-button @click="editForm" icon="edit" type="primary">{{previewForm.type == 'survey' ? 'EDIT THIS SURVEY' : previewForm.type == 'worksheet' ? 'EDIT THIS WORKSHEET' : 'EDIT THIS FORM'}}</a-button>
                </div>
            </a-col>
        </a-row>
        <div style="width: 100%; padding: 24px;">
            <a-card :title="previewForm.name" style="width: 50%; margin-left: auto; margin-right: auto; margin-top: 100px; margin-bottom: 100px;">
                <div slot="extra">{{previewForm.options.supporting}}</div>
                <a-form-model ref="ruleForm" :model="previewForm" :rules="rules">
                    <template v-for="(field,fieldI) in previewForm.fields">

                        <!-- Email Field -->
                        <template v-if="field.type === 'email'">
                            <a-form-model-item :ref="fieldI" :label="field.label" :prop="`${fieldI}`" :key="`field${fieldI}`">
                                <a-input
                                    size="large"
                                    :placeholder="field.placeholder"
                                    >
                                </a-input>
                            </a-form-model-item>
                        </template>

                        <!-- Name Field -->
                        <template v-else-if="field.type === 'name' && !field.hide">
                            <a-form-model-item :ref="fieldI" :label="field.label" :prop="`${fieldI}`"  :key="`fieldname${fieldI}`" style="margin-bottom: 0;">
                                <a-row :gutter="16">
                                    <div style="display: flex; justify-content: space-between;">
                                        <a-col v-if="field.prefix" style="width: 30%;">
                                            <a-form-item label="Prefix">
                                                <a-input
                                                    size="large"
                                                    >
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
                                        <a-col style="width: 100%;">
                                            <a-form-item label="First Name">
                                                <a-input
                                                    size="large"
                                                    >
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
                                        <a-col v-if="field.middleInitial" style="width: 30%;">
                                            <a-form-item label="Middle Initial">
                                                <a-input
                                                    size="large"
                                                    >
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
                                        <a-col style="width: 100%;">
                                            <a-form-item label="Last Name">
                                                <a-input
                                                    size="large"
                                                    >
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
                                        <a-col v-if="field.suffix" style="width: 30%;">
                                            <a-form-item label="Suffix">
                                                <a-input
                                                    size="large"
                                                    >
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
                                    </div>
                                </a-row>
                            </a-form-model-item>
                        </template>

                        <!-- Phone Field -->
                        <template v-else-if="field.type === 'phone' && !field.hide">
                            <a-form-model-item :ref="fieldI" :label="field.label" :prop="`${fieldI}`"  :key="`field${fieldI}`">
								<a-input
                                    size="large"
                                    :placeholder="field.placeholder"
                                    type="number"
                                    :min="field.min"
                                    :max="field.max"
                                    :maxLength="field.digitLength || null"
                                    :minLength="field.digitLength || null"
                                    :oninput="field.digitLength?'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);' : ''"
                                    :default-value="field.default"
                                    >
                                </a-input>
                            </a-form-model-item>
                        </template>

                        <!-- Address Field -->
                        <template v-else-if="field.type === 'address' && !field.hide">
                            <a-form-model-item :ref="fieldI" :label="field.label" :prop="`${fieldI}`" :key="`field${fieldI}`" style="margin-bottom: 0;">
                                <a-row :gutter="16">
                                    <a-col v-if="field.options.addressOne" :span="24">
                                        <a-form-item label="Address Line 1">
                                            <a-input
                                                size="large"
                                                >
                                            </a-input>
                                        </a-form-item>
                                    </a-col>
                                </a-row>
                                <a-row :gutter="16">
                                    <a-col :span="24">
                                        <a-form-item v-if="field.options.addressTwo" label="Address Line 2">
                                            <a-input
                                                size="large"
                                                >
                                            </a-input>
                                        </a-form-item>
                                    </a-col>
                                </a-row>
                                <a-row v-if="field.options.city || field.options.province || field.options.postal" :gutter="16">
                                    <div style="display: flex; justify-content: space-between;">
                                        <a-col :span="6" v-show="field.options.postal">
                                            <a-form-item label="ZIP/Postal Code">
                                                <a-input
                                                    size="large"
                                                    >
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
                                        <a-col :span="6" v-show="field.options.city">
                                            <a-form-item label="City">
                                                <a-input
                                                    size="large"
                                                    >
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
                                        <a-col :span="6" v-show="field.options.province">
                                            <a-form-item label="State/Province">
                                                <a-input
                                                    size="large"
                                                    >
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
                                        <a-col :span="6" v-show="field.options.country">
                                            <a-form-item label="Country">
                                                <a-select
                                                    v-model="field.country"
                                                    style="width:100%"
                                                    size="large"
                                                    >
                                                    <a-select-option value="Canada">Canada</a-select-option>
                                                    <a-select-option value="United States">United States</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                    </div>
                                </a-row>
                            </a-form-model-item>
                        </template>

                        <!-- isAgent Field -->
                        <template v-else-if="field.type === 'isAgent' && !field.hide">
                            <template v-if="field.displayType == 'checkbox'">
                                <a-form-model-item  :key="`fieldaent${fieldI}`">
                                    <a-checkbox style="display: block; height: 32px; margin-left: 0 !important;">
                                        {{field.label}}
                                    </a-checkbox>
                                </a-form-model-item>
                            </template>


                            <template v-else-if="field.displayType == 'radio'">
                                <a-form-model-item :ref="fieldI" :label="field.label" :prop="`${fieldI}`"  :key="`fieldradio${fieldI}`">
                                    <a-radio-group :default-value="field.default">
                                        <template v-for="(option,optionI) in field.options">
                                            <a-radio style="display: block; height: 32px;" :value="option.option" :key="option+optionI">
                                                {{option.option}}
                                            </a-radio>
                                        </template>
                                    </a-radio-group>
                                </a-form-model-item>
                            </template>


                            <template v-else>
                                <a-form-model-item :ref="fieldI" :label="field.label" :prop="`${fieldI}`" :key="`fieldselect${fieldI}`">
                                    <a-select :default-value="field.default" placeholder="Select" size="large">
                                        <a-select-option v-for="(option, optionI) in field.options" :key="optionI">{{option.option}}</a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </template>
                        </template>

                        <!-- Text Field -->
                        <template v-else-if="field.type === 'text'">
                            <a-form-model-item :ref="fieldI" :label="field.label" :prop="`${fieldI}`"  :key="`textield${fieldI}`">
                                <a-input
                                    size="large"
                                    :placeholder="field.placeholder"
                                    :default-value="field.default"
                                    >
                                </a-input>
                            </a-form-model-item>
                        </template>

                        <!-- Number Field -->
                        <template v-else-if="field.type === 'number'">
                            <a-form-model-item :ref="fieldI" :label="field.label" :prop="`${fieldI}`"  :key="`numfield${fieldI}`">
                                <a-input
                                    size="large"
                                    :placeholder="field.placeholder"
                                    type="number"
                                    :min="field.min"
                                    :max="field.max"
                                    :maxLength="field.digitLength || null"
                                    :minLength="field.digitLength || null"
                                    :oninput="field.digitLength?'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);' : ''"
                                    :default-value="field.default"
                                    >
                                </a-input>
                            </a-form-model-item>
                        </template>

                        <!-- Dropdown Field -->
                        <template v-else-if="field.type === 'dropdown'">
                            <a-form-model-item :ref="fieldI" :label="field.label" :prop="`${fieldI}`"  :key="`ddfield${fieldI}`">
                                <a-select :default-value="field.default" placeholder="Select" size="large">
                                    <a-select-option v-for="(option, optionI) in field.options" :key="optionI">{{option.option}}</a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </template>

                        <!-- Radio Field -->
                        <template v-else-if="field.type === 'radio'">
                            <a-form-model-item :ref="fieldI" :label="field.label" :prop="`${fieldI}`" :key="`rdfield${fieldI}`">
                                <a-radio-group :default-value="field.default">
                                    <template v-for="(option,optionI) in field.options">
                                        <a-radio style="display: block; height: 32px;" :value="option.option" :key="option+optionI">
                                            {{option.option}}
                                        </a-radio>
                                    </template>
                                    <a-radio v-if="field.other" style="display: block; height: 32px;" value="other">
                                        <span class="mr-2" style="padding-left: 2px;" v-if="field.otherText">{{field.otherText}}</span><a-input :style="{ width: 100, marginLeft: 10 }" />
                                    </a-radio>
                                </a-radio-group>
                            </a-form-model-item>
                        </template>

                        <!-- Checkbox Field -->
                        <template v-else-if="field.type === 'checkbox'">
                            <template v-if="field.multiple">
                                <a-form-model-item :ref="fieldI" :label="field.label" :prop="`${fieldI}`"  :key="`chxfield${fieldI}`">
                                    <a-checkbox-group :default-value="[field.default]">
                                        <template v-for="(option,optionI) in field.options">
                                            <a-checkbox style="display: block; height: 32px; margin-left: 0 !important;" :value="option.option" :key="option+optionI">
                                                {{option.option}}
                                            </a-checkbox>
                                        </template>
                                        <a-checkbox v-if="field.other" style="display: flex; align-items: center; height: 32px; margin-left: 0 !important;" value="other">
                                            <div class="dF aC">
												<span class="mr-2" style="padding-left: 2px;" v-if="field.otherText">{{field.otherText}}</span>
												<a-input :style="{ width: 100, marginLeft: 10 }" />
											</div>
                                        </a-checkbox>
                                    </a-checkbox-group>
                                </a-form-model-item>
                            </template>
                            <template v-else>
                                <a-form-model-item  :key="`fxxxield${fieldI}`">
                                    <a-checkbox style="display: block; height: 32px; margin-left: 0 !important;">
                                        {{field.label}}
                                    </a-checkbox>
                                </a-form-model-item>
                            </template>
                        </template>

                        <!-- File Field -->
                        <template v-else-if="field.type === 'file'">
                            <a-form-model-item :ref="fieldI" :label="field.label" :prop="`${fieldI}`"  :key="`fielfiled${fieldI}`">
                                <a-upload>
                                    <a-button> <a-icon type="upload" /> Upload </a-button>
                                </a-upload>
                            </a-form-model-item>
                        </template>

                        <!-- Header Field -->
                        <template v-else-if="field.type === 'header'">
                            <div class="mt-5" :key="`fieldheader${fieldI}`">
                                <div :key="`fiexxld${fieldI}`" style="font-size: 20px; font-weight: bold;">
                                    {{field.label}}
                                </div>
                                <div v-if="field.description != ''" :key="`field-header${fieldI}`">
                                    {{field.description}}
                                </div>
                                <hr :key="`asfield${fieldI}`" />
                            </div>
                        </template>

                        <!-- Date/Time Field -->
                        <template v-else-if="field.type === 'date'">
                            <a-form-model-item :ref="fieldI" :label="field.label" :prop="`${fieldI}`" :key="`datefield${fieldI}`">
                                <div class="dF">
                                    <a-date-picker :default-value="field.default" size="large" :placeholder="field.placeholder" class="mr-3" />
                                    <a-date-picker v-if="field.timeEnable" size="large" show-time placeholder="Select Time" />
                                </div>
                            </a-form-model-item>
                        </template>

                        <!-- Tag Field -->
                        <template v-else-if="field.type == 'tag'">
                            <template v-if="field.multiple">
                                <a-form-model-item :ref="fieldI" :label="field.label" :prop="`${fieldI}`" :key="`ftagield${fieldI}`" :required="field.required">
                                    <a-checkbox-group class="mt-2">
										<a-checkbox v-show="field.selectAll" style="display: block; height: 32px; margin-left: 0 !important;">
											Select All
										</a-checkbox>
                                        <template v-for="(option,optionI) in field.options">
                                            <a-checkbox style="display: block; height: 32px; margin-left: 0 !important;" :value="option.option" :key="option+optionI">
                                                {{option.option}}
                                            </a-checkbox>
                                        </template>
                                    </a-checkbox-group>
                                </a-form-model-item>
                            </template>
                            <template v-else>
                                <a-form-model-item :ref="fieldI" :label="field.label" :prop="`${fieldI}`" :key="`fielselectd${fieldI}`" :required="field.required">
                                    <a-select placeholder="Select" size="large">
                                        <a-select-option v-for="(option, optionI) in field.options" :key="optionI">{{option.option}}</a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </template>
                        </template>

                        <!-- Select Child -->
                        <template v-else-if="field.type == 'child' && !field.hide">
                            <a-form-model-item :ref="fieldI" :label="field.label" :prop="`${fieldI}`"  :key="`fiechildld${fieldI}`" :required="field.required">
								<a-checkbox v-show="field.selectAll" style="display: block; height: 32px; margin-left: 0 !important;">
									Select All
								</a-checkbox>
								<template v-for="(option,optionI) in field.options">
									<a-checkbox v-if="option.active" v-model="option.selected" :checked="option.selected" style="display: block; height: 32px; margin-left: 0 !important;" :value="option.id" :key="option+optionI">
										{{!option.hasOwnProperty('name') || option.name == '' ? option.label : option.name}}
									</a-checkbox>
								</template>
                            </a-form-model-item>
                        </template>

						<!-- worksheet fields -->

						<!-- Lot or Unit Field -->
                        <template v-else-if="['lot', 'unit'].includes(field.type) && !field.hide">
							<a-form-model-item :ref="fieldI" :label="field.label" :prop="`${fieldI}`"  :key="`fieldname${fieldI}`" style="margin-bottom: 0;">
                                <a-row :gutter="16">
                                    <div style="display: flex; justify-content: space-between;">
                                        <a-col style="width: 100%;">
                                            <a-form-item :label="field.label1" :required="field.required1">
												<a-select style="width: 100%;" size="large" :placeholder="field.placeholder1">
													<a-select-option v-for="(option, optionI) in field.options" :key='optionI + option.option' :value="option.option">{{option.option}}</a-select-option>
												</a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col v-show="field.showSecondChoice" style="width: 100%;">
                                            <a-form-item :label="field.label2" :required="field.required2">
                                                <a-select style="width: 100%;" size="large" :placeholder="field.placeholder2">
													<a-select-option v-for="(option, optionI) in field.options" :key='optionI + option.option' :value="option.option">{{option.option}}</a-select-option>
												</a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col v-show="field.showThirdChoice" style="width: 100%;">
                                            <a-form-item :label="field.label3" :required="field.required3">
                                                <a-select style="width: 100%;" size="large" :placeholder="field.placeholder3">
													<a-select-option v-for="(option, optionI) in field.options" :key='optionI + option.option' :value="option.option">{{option.option}}</a-select-option>
												</a-select>
                                            </a-form-item>
                                        </a-col>
                                    </div>
                                </a-row>
                            </a-form-model-item>
						</template>

						<!-- Lot Field -->
                        <template v-else-if="field.type === 'lot' && !field.hide">
							<a-form-model-item :ref="fieldI" :label="field.label" :prop="`${fieldI}`"  :key="`fieldname${fieldI}`" style="margin-bottom: 0;">
                                <a-row :gutter="16">
                                    <div style="display: flex; justify-content: space-between;">
                                        <a-col style="width: 100%;">
                                            <a-form-item :label="field.label1" :required="field.required1">
												<a-select style="width: 100%;" size="large" :placeholder="field.placeholder1">
													<a-select-option v-for="(option, optionI) in field.options" :key='optionI + option.option' :value="option.option">{{option.option}}</a-select-option>
												</a-select>
                                            </a-form-item>
											<a-form-item :label="field.elevationLabel1" :required="field.required1">
												<a-select style="width: 100%;" size="large" :placeholder="field.elevationPlaceholder1">
													<a-select-option v-for="(option, optionI) in field.options" :key='optionI + option.option' :value="option.option">{{option.option}}</a-select-option>
												</a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col v-show="field.showSecondChoice" style="width: 100%;">
                                            <a-form-item :label="field.label2" :required="field.required2">
                                                <a-select style="width: 100%;" size="large" :placeholder="field.placeholder2">
													<a-select-option v-for="(option, optionI) in field.options" :key='optionI + option.option' :value="option.option">{{option.option}}</a-select-option>
												</a-select>
                                            </a-form-item>
											<a-form-item :label="field.elevationLabel2" :required="field.required2">
												<a-select style="width: 100%;" size="large" :placeholder="field.elevationPlaceholder2">
													<a-select-option v-for="(option, optionI) in field.options" :key='optionI + option.option' :value="option.option">{{option.option}}</a-select-option>
												</a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col v-show="field.showThirdChoice" style="width: 100%;">
                                            <a-form-item :label="field.label3" :required="field.required3">
                                                <a-select style="width: 100%;" size="large" :placeholder="field.placeholder3">
													<a-select-option v-for="(option, optionI) in field.options" :key='optionI + option.option' :value="option.option">{{option.option}}</a-select-option>
												</a-select>
                                            </a-form-item>
											<a-form-item :label="field.elevationLabel3" :required="field.required3">
												<a-select style="width: 100%;" size="large" :placeholder="field.elevationPlaceholder3">
													<a-select-option v-for="(option, optionI) in field.options" :key='optionI + option.option' :value="option.option">{{option.option}}</a-select-option>
												</a-select>
                                            </a-form-item>
                                        </a-col>
                                    </div>
                                </a-row>
                            </a-form-model-item>
						</template>

						<!-- Add Ons Field -->
                        <template v-else-if="field.type === 'addOns' && !field.hide">
							<a-form-model-item :ref="fieldI" :label="field.label" :prop="`${fieldI}`"  :key="`fieldname${fieldI}`" style="margin-bottom: 0;">
                                <a-row :gutter="16">
                                    <div style="display: flex; justify-content: space-between;">
                                        <a-col v-show="field.parking.show" style="width: 100%;">
                                            <a-form-item :label="field.parking.label" :required="field.parking.required">
												<a-select style="width: 100%;" size="large" :placeholder="field.parking.placeholder">
													<a-select-option v-for="(option, optionI) in field.parking.options" :key='optionI + option.option' :value="option.option">{{option.option}}</a-select-option>
												</a-select>
                                            </a-form-item>
                                        </a-col>
										<a-col v-show="field.lockers.show" style="width: 100%;">
                                            <a-form-item :label="field.lockers.label" :required="field.lockers.required">
												<a-select style="width: 100%;" size="large" :placeholder="field.lockers.placeholder">
													<a-select-option v-for="(option, optionI) in field.lockers.options" :key='optionI + option.option' :value="option.option">{{option.option}}</a-select-option>
												</a-select>
                                            </a-form-item>
                                        </a-col>
										<a-col v-show="field.bikeRacks.show" style="width: 100%;">
                                            <a-form-item :label="field.bikeRacks.label" :required="field.bikeRacks.required">
												<a-select style="width: 100%;" size="large" :placeholder="field.bikeRacks.placeholder">
													<a-select-option v-for="(option, optionI) in field.bikeRacks.options" :key='optionI + option.option' :value="option.option">{{option.option}}</a-select-option>
												</a-select>
                                            </a-form-item>
                                        </a-col>
                                    </div>
                                </a-row>
                            </a-form-model-item>
						</template>

						<!-- Purchaser Field -->
                        <template v-else-if="field.type === 'purchaser' && !field.hide">
                            <a-form-model-item :ref="fieldI" :label="field.label" :prop="`${fieldI}`" :key="`fieldname${fieldI}`" style="margin-bottom: 0;">
                                <a-row :gutter="16">
                                    <div style="display: flex; justify-content: space-between;">
                                        <a-col style="width: 100%;">
                                            <a-form-item label="First Name" :required="field.required">
                                                <a-input size="large">
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
                                        <a-col style="width: 100%;">
                                            <a-form-item label="Last Name" :required="field.required">
                                                <a-input size="large">
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
										<a-col style="width: 100%;">
                                            <a-form-item label="Middle Name">
                                                <a-input size="large">
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
                                    </div>
                                </a-row>
								<a-row :gutter="16">
                                    <div style="display: flex; justify-content: space-between;">
                                        <a-col style="width: 100%;">
                                            <a-form-item label="Date of Birth" :required="field.required">
                                                <a-input size="large">
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
                                        <a-col style="width: 100%;">
                                            <a-form-item label="Email" :required="field.required">
                                                <a-input size="large">
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
										<a-col style="width: 100%;">
                                            <a-form-item label="Phone #" :required="field.required">
                                                <a-input size="large">
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
                                    </div>
                                </a-row>
								<a-row :gutter="16">
                                    <div style="display: flex; justify-content: space-between;">
                                        <a-col style="width: 100%;">
                                            <a-form-item label="Business Phone#">
                                                <a-input size="large">
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
										<a-col style="width: 100%;">
                                            <a-form-item label="Cell Phone#">
                                                <a-input size="large">
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
										<a-col style="width: 100%;">
                                            <a-form-item label="Company">
                                                <a-input size="large">
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
                                    </div>
                                </a-row>
								<a-row :gutter="16">
                                    <div style="display: flex; justify-content: space-between;">
										<a-col style="width: 100%;">
                                            <a-form-item label="Occupation">
                                                <a-input size="large">
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
                                        <a-col style="width: 100%;">
                                            <a-form-item label="Job Title">
                                                <a-input size="large">
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
                                        <a-col style="width: 100%;">
                                            <a-form-item label="SSN/SIN #">
                                                <a-input size="large">
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
                                    </div>
                                </a-row>
								<a-row :gutter="16">
                                    <div style="display: flex; justify-content: space-between;">
										<a-col style="width: 100%;">
                                            <a-form-item label="Driver License #" :required="field.required">
                                                <a-input size="large">
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
                                        <a-col style="width: 100%;">
                                            <a-form-item label="License Issuing Jurisdiction">
                                                <a-input size="large">
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
										<a-col style="width: 100%;">
                                            <a-form-item label="License Expiry">
												<a-date-picker placeholder="eg. 1984-02-28" style=" width:100%" size="large"/>
                                            </a-form-item>
                                        </a-col>
                                    </div>
                                </a-row>
								<a-row :gutter="16">
                                    <div style="display: flex; justify-content: space-between;">
										<a-col style="width: 100%;">
                                            <a-form-item label="Identification Type" :required="field.required">
												<a-select default-value="pp" v-model="field.idType" style="width: 100%;" size="large">
													<a-select-option value="pp">US/CA Passport</a-select-option>
													<a-select-option value="other">Other</a-select-option>
													<a-select-option v-for="(option, optionI) in field.options" :key='optionI + option.option' :value="option.option">{{option.option}}</a-select-option>
												</a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col style="width: 100%;">
                                            <a-form-item :label="field.idType === 'pp' ? 'Passport #' : 'Other #'" :required="field.required">
                                                <a-input size="large">
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
                                        <a-col style="width: 100%;">
                                            <a-form-item :label="field.idType === 'pp' ? 'Passport Issuing Jurisdiction' : 'Other Issuing Jurisdiction'">
                                                <a-input size="large">
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
                                    </div>
                                </a-row>
								<a-row :gutter="16">
									<div style="display: flex; justify-content: space-between;">
										<a-col style="width: 100%;">
	                                            <a-form-item :label="field.idType === 'pp' ? 'Passport Expiry' : 'Other Expiry'">
													<a-date-picker placeholder="eg. 1984-02-28" style=" width:100%" size="large"/>
	                                            </a-form-item>
	                                        </a-col>
	                                    <a-col style="width: 100%;">
	                                        <a-form-item label="Address Line 1" :required="field.required">
	                                            <a-input size="large">
	                                            </a-input>
	                                        </a-form-item>
	                                    </a-col>
										<a-col style="width: 100%;">
	                                        <a-form-item  label="Address Line 2">
	                                            <a-input size="large">
	                                            </a-input>
	                                        </a-form-item>
	                                    </a-col>
									</div>
                                </a-row>
                                <a-row :gutter="16">
                                    <div style="display: flex; justify-content: space-between;">
										<a-col :span="6">
                                            <a-form-item label="City" :required="field.required">
                                                <a-input size="large">
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
                                        <a-col :span="6">
                                            <a-form-item label="State/Province" :required="field.required">
                                                <a-input size="large">
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
                                        <a-col :span="6">
                                            <a-form-item label="ZIP/Postal Code" :required="field.required">
                                                <a-input size="large">
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
                                        <a-col :span="6">
                                            <a-form-item label="Country" :required="field.required">
                                                <a-select
                                                    style=" width:100%"
                                                    size="large"
                                                    >
                                                    <a-select-option value="Canada">Canada</a-select-option>
                                                    <a-select-option value="United States">United States</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                    </div>
                                </a-row>
                            </a-form-model-item>
                        </template>

						<!-- solicitor Field -->
                        <template v-else-if="field.type === 'solicitor' && !field.hide">
							<a-form-model-item :ref="fieldI" :label="field.label" :prop="`${fieldI}`"  :key="`fieldname${fieldI}`" style="margin-bottom: 0;">
                                <a-row :gutter="16">
                                    <a-col :span="8">
                                        <a-form-item label="First Name" :required="field.required">
                                            <a-input size="large">
                                            </a-input>
                                        </a-form-item>
                                    </a-col>
                                    <a-col :span="8">
                                        <a-form-item label="Last Name" :required="field.required">
                                            <a-input size="large">
                                            </a-input>
                                        </a-form-item>
                                    </a-col>
									<a-col :span="8">
                                        <a-form-item label="Email" :required="field.required">
                                            <a-input size="large">
                                            </a-input>
                                        </a-form-item>
                                    </a-col>
                                </a-row>
								<a-row :gutter="16">
									<a-col :span="8">
                                        <a-form-item label="Phone #">
                                            <a-input size="large">
                                            </a-input>
                                        </a-form-item>
                                    </a-col>
									<a-col :span="8">
                                        <a-form-item label="Fax #">
                                            <a-input size="large">
                                            </a-input>
                                        </a-form-item>
                                    </a-col>
									<a-col :span="8">
                                        <a-form-item label="Company Name">
                                            <a-input size="large">
                                            </a-input>
                                        </a-form-item>
                                    </a-col>
                                </a-row>
								<a-row :gutter="16">
									<a-col :span="8">
                                        <a-form-item label="Job Title">
                                            <a-input size="large">
                                            </a-input>
                                        </a-form-item>
                                    </a-col>
                                    <a-col :span="8">
                                        <a-form-item label="Address Line 1" :required="field.required">
                                            <a-input size="large">
                                            </a-input>
                                        </a-form-item>
                                    </a-col>
									<a-col :span="8">
                                        <a-form-item  label="Address Line 2">
                                            <a-input size="large">
                                            </a-input>
                                        </a-form-item>
                                    </a-col>
                                </a-row>
                                <a-row :gutter="16">
                                    <div style="display: flex; justify-content: space-between;">
										<a-col :span="6">
                                            <a-form-item label="City" :required="field.required">
                                                <a-input
                                                    size="large"
                                                    >
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
                                        <a-col :span="6">
                                            <a-form-item label="State/Province" :required="field.required">
                                                <a-input
                                                    size="large"
                                                    >
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
                                        <a-col :span="6">
                                            <a-form-item label="ZIP/Postal Code" :required="field.required">
                                                <a-input
                                                    size="large"
                                                    >
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
                                        <a-col :span="6">
                                            <a-form-item label="Country" :required="field.required">
												<a-input
                                                    size="large"
                                                    >
                                                </a-input>
                                            </a-form-item>
                                        </a-col>
                                    </div>
                                </a-row>
                            </a-form-model-item>
						</template>
                    </template>
                </a-form-model>
                <div v-if="previewForm.options && previewForm.options.consent && previewForm.options.consent.enabled" class="dF mt-5">
                    <a-checkbox>{{previewForm.options.consent.text == '' ? 'I Consent' : previewForm.options.consent.text}}</a-checkbox>
                </div>
				<div v-if="previewForm.options && previewForm.options.smsConsent && previewForm.options.smsConsent.enabled" class="dF mt-5">
                    <a-checkbox>{{previewForm.options.smsConsent.text == '' ? 'I Consent' : previewForm.options.smsConsent.text}}</a-checkbox>
                </div>
                <div class="mt-3" style="width: 100%; text-align: center;">
                    <a-button @click="submitClicked" type="primary" size="large" class="mt-4">{{previewForm.options.submitLabel == ''? 'SUBMIT':previewForm.options.submitLabel}}</a-button>
                </div>
            </a-card>
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex'
import {getInitial, getAppIcon} from 'bh-mod'
let tld = window.location.href.includes('localhost') || window.location.href.includes('.dev') ? 'dev' : 'com'

export default {
  components: {

  },
  data(){
    return {
        showSubmit:false,
        rules: {

        },
        previewForm:{}

    }
  },
  computed:{
    ...mapState(['settings']),
    formType(){
        return this.$store.state.formType
    },
    tld(){
      return tld
    },
    theApp(){
      return this.$store.state.theApp || {}
    },
    instance(){
      return this.$store.state.instance
    },
    apps(){
      return this.instance.apps.filter(x => {
        return x.subdomain !== this.$bhapp
      })
    },
    appsGrouped(){
        let apps = this.apps || []
        let cats = {}

        apps.forEach(app => {
            let cat = app.media.category || 'Apps'
            if (!cats[cat]) cats[cat] = {name:cat,apps:[]}
            cats[cat].apps.push(app)
        })

        return Object.values(cats)

    },
    dialog(){
        return this.$store.state.forms.formDrawer
    },
    forms(){
        return Object.values(this.$store.state.forms.allForms)
    },

  },
  methods:{
    getInitial,
    getAppIcon,
    submitClicked(){
        console.log('SUBMIT CLICKED')
        this.showSubmit = true
    },
    editForm(){
        this.$router.push(`/edit/${this.$route.params.formID}`)
    },
    back(){
        this.$router.push('/')
    },
    getCurrentStyle(current, today) {
        const style = {};
        if (current.date() === 1) {
            style.border = '1px solid #1890ff';
            style.borderRadius = '50%';
        }
        return style;
    },
  },
  async created(){
        this.previewForm = JSON.parse(JSON.stringify(this.forms.find(x => x.id == this.$route.params.formID)))
        let fields = this.previewForm.fields
        for (var i = 0; i < fields.length; i++){
            console.log('FIELDSSSS', fields[i])
            if (fields[i].required){
                this.rules[i] = [{required: true, message: 'Field is required!', trigger: 'blur'}]
            }
        }
        console.log('RULESSSS', this.rules)
  }
}
</script>

<style lang="scss">
@import '~bh-mod/scss/mixins';

.topbar {
  background: transparent;
  min-height: rem(64);
  border-bottom: 1px solid $bh-line-grey;
  border-color:transparent;
  padding: rem(15) rem(20);
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  box-shadow:1px 1px 2px rgba(100,100,100,0.1);
  position: relative;
  z-index:100;
}
</style>

<style>
    .previewPage {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: white;
    }
</style>
